import { PerspectiveCamera } from 'three/src/cameras/PerspectiveCamera';
import * as MathUtils from 'three/src/math/MathUtils.js';

class DOMCamera extends PerspectiveCamera {

	constructor( fov = 50, near = 0.1, far = 2000, unitConversionFactor = 1, scroll = true) {

		super(fov, 1, near, far);

		this.scroll = scroll;
		this.unitConversionFactor = unitConversionFactor;
		this.type = 'DOMCamera';
		this.fovHorizontal = fov;
		this.up.set(0, -1, 0);
		this.updateAspect();
		this.updatePosition();

		this.updateProjectionMatrix();
	}

	/**
	 * Sets the aspect ratio using window width and height.
	 */
	updateAspect() {

		this.aspect = window.innerWidth / window.innerHeight;
		this.fovHorizontal = 2 * Math.atan( this.aspect * Math.tan( this.fov * MathUtils.DEG2RAD / 2 ) ) * MathUtils.RAD2DEG

	}

	/**
	 * Sets the camera position so that (x,y) coordinates match DOM coordinates.
	 *
	 * Call this method on:
	 * - aspect ratio change
	 * - FOV change
	 * - window scroll (if scroll is set to true)
	 */
	updatePosition() {
		let w = window.innerWidth * this.unitConversionFactor,
			h = window.innerHeight * this.unitConversionFactor,
			scrollY = this.scroll ? window.scrollY * this.unitConversionFactor : 0,
			scrollX = this.scroll ? window.scrollX * this.unitConversionFactor : 0,
			x = w / 2 + scrollX,
			y = h / 2 + scrollY;

		this.position.x = x;
		this.position.y = y;
		this.position.z = -h * 0.5 / Math.tan(this.getEffectiveFOV() * 0.5 * MathUtils.DEG2RAD);
		this.lookAt(x, y, 0);
	}

	copy( source, recursive ) {

		super.copy( source, recursive );

		this.scroll = source.scroll;
		this.unitConversionFactor = source.unitConversionFactor;
		this.fovHorizontal = source.fovHorizontal;

		return this;

	}

	/**
	 * Sets the FOV by focal length in respect to the current .filmGauge.
	 *
	 * The default film gauge is 35, so that the focal length can be specified for
	 * a 35mm (full frame) camera.
	 *
	 * Values for focal length and film gauge must have the same unit.
	 */
	setFocalLength( focalLength ) {

		super.setFocalLength(focalLength);

		this.updatePosition();

	}

	toJSON( meta ) {

		const data = super.toJSON( meta );

		data.scroll = this.scroll;
		data.unitConversionFactor = this.unitConversionFactor;
		data.fovHorizontal = this.fovHorizontal;

		return data;

	}

}

DOMCamera.prototype.isPerspectiveCamera = true;

export { DOMCamera };
