import gsap from "gsap";

(function() {
  const scaleVectorLength = Math.sqrt(3);

  function getThreeValues(target) {
    return {
      x: target.position.x,
      y: target.position.y,
      z: target.position.z,
      rotationX: target.rotation.x,
      rotationY: target.rotation.y,
      rotationZ: target.rotation.z,
      scale: target.scale.length() / scaleVectorLength,
      scaleX: target.scale.x,
      scaleY: target.scale.y,
      scaleZ: target.scale.z
    }
  }

  gsap.registerPlugin({
    name: "three",
    get(target) {
      return getThreeValues(target);
    },
    init(target, endValue) {
      let data = this,
        startValues = getThreeValues(target);

      data.target = target;
      data.three = {};
      data.threeSetters = {
        x(val) { target.position.x = val },
        y(val) { target.position.y = val },
        z(val) { target.position.z = val },
        rotationX(val) { target.rotation.x = val },
        rotationY(val) { target.rotation.y = val },
        rotationZ(val) { target.rotation.z = val },
        scale(val) { target.scale.setLength(val * scaleVectorLength) },
        scaleX(val) { target.scale.x = val },
        scaleY(val) { target.scale.y = val },
        scaleZ(val) { target.scale.z = val },
      };

      for (let key in endValue) {
        data.three[key] = {};
        data.three[key].interp = gsap.utils.interpolate(startValues[key], endValue[key]);
      }
    },
    render(progress, data) {
      for (let key in data.three) {
        data.threeSetters[key](data.three[key].interp(progress))
      }
    }
  });
})();
