<template>
  <div id="foreground-animation" ref="threeContainer">

  </div>
</template>

<script>
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DOMCamera } from "@/three/DOMCamera";

import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import gsapPlugin from './../three/gsapPlugin'
import VertexShader from 'raw-loader!@/assets/vertex-shader.txt'
import FragmentShader from 'raw-loader!@/assets/fragment-shader.txt'
import debounce from 'debounce'

gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'ForegroundAnimation',
  components: {
  },
  data () {
    return {
      seed: 3, // 5,7
      camera: null,
      horizontalFov: 100,
      scene: null,
      renderer: null,
      container: null,
      tl: null,
      unitConversionFactor: 0.001,
    }
  },
  methods: {
    seededRandom () {
      const x = Math.sin(this.seed++) * 10000

      return x - Math.floor(x)
    },
    pixelsToThreeUnits (val) {
      return val * this.unitConversionFactor
    },
    init () {
      let that = this
      this.camera = new DOMCamera(75, 0.001, 1000, this.unitConversionFactor, false)
      this.scene = new THREE.Scene()
      this.scene.fog = new THREE.Fog(0xffffff)
      this.scene.fog.update = function () {
        this.near = Math.abs(that.camera.position.z) - 1
        this.far = Math.abs(that.camera.position.z) + 5
      }
      this.scene.fog.update();

      const light = new THREE.PointLight(0xffffff, 1)
      light.update = function () {
        let x = that.pixelsToThreeUnits(window.innerWidth) / 2,
            y = that.pixelsToThreeUnits(window.innerHeight) / 2

        this.position.set(x, y, -0.3)
      }
      light.name = 'light'
      light.update();
      this.scene.add(light)

      const ambientLight = new THREE.AmbientLight(0x174f62, 0.1)
      ambientLight.name = 'ambientLight'
      this.scene.add(ambientLight)

      const vertexColorMaterial = new THREE.MeshPhongMaterial({
        vertexColors: true,
        flatShading: true,
        fog: true,
        shininess: 30
      });

      const colors = []
      colors.push(new THREE.Color(0x238db9))
      colors.push(new THREE.Color(0x2d90ae))
      colors.push(new THREE.Color(0x174F62))
      colors.push(new THREE.Color(0x37c0a4))

      const shapes = new THREE.Group()
      shapes.name = 'shapes'
      const loader = new GLTFLoader().setPath('/')

      loader.load('shapes_v5.gltf', (gltf) => {
        let bodyHeight = this.pixelsToThreeUnits(document.body.clientHeight)
        let shapeDistribution = {
          n: Math.ceil( bodyHeight / .8 ),
          dy: .8,
        }

        let shapeTemplates = gltf.scene.getObjectByName('shapes').children

        // distribute shapes
        for (let i = 0; i < shapeDistribution.n; i++) {
          let clonedShape = shapeTemplates[i % shapeTemplates.length].clone(); // cycle through available shape templates

          let colorArray = []

          for (let j = 0; j < clonedShape.geometry.attributes.position.count; j++) {
            let color = colors[Math.floor(this.seededRandom() * colors.length)];

            colorArray.push(Math.round(color.r * 255))
            colorArray.push(Math.round(color.g * 255))
            colorArray.push(Math.round(color.b * 255))
          }

          clonedShape.geometry.setAttribute( 'color', new THREE.BufferAttribute( new Uint8Array(colorArray), 3, true) );
          clonedShape.material = vertexColorMaterial

          let randX = this.seededRandom();

          clonedShape.updatePosition = function () {
            let scale = Math.min(window.innerWidth / 9000, 0.25)
            this.position.y = .5 + shapeDistribution.dy * i
            this.scale.set(scale, scale, scale)
            if (i % 2 > 0)
              this.position.x = that.pixelsToThreeUnits(window.innerWidth) + randX * 0.1;
            else
              this.position.x = -randX * 0.1
          }
          clonedShape.updatePosition()
          //clonedShape.scale.multiplyScalar(.5)

          shapes.add(clonedShape)
        }

        this.scene.add(shapes)

        this.render()
        this.animate()
      })

      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
      this.renderer.setPixelRatio(window.devicePixelRatio)
      this.renderer.setSize(this.container.clientWidth, this.container.clientHeight)
      this.renderer.toneMappingExposure = 1
      this.renderer.outputEncoding = THREE.sRGBEncoding

      this.container.appendChild(this.renderer.domElement)

      window.addEventListener('resize', debounce(this.onWindowResize, 200))
    },
    onWindowResize () {
      this.camera.updateAspect()
      this.camera.updateProjectionMatrix()
      this.camera.updatePosition()
      this.scene.fog.update()
      this.scene.getObjectByName('light').update()

      this.renderer.setSize(this.container.clientWidth, this.container.clientHeight)

      for (let shape of this.scene.getObjectByName('shapes').children) {
        shape.updatePosition()
      }

      this.render()
    },
    render () {
      requestAnimationFrame(this.render)
      this.renderer.render(this.scene, this.camera)
    },
    animate () {
      const scrollHeight = () => { return document.body.clientHeight - window.innerHeight }
      const randomAngle = () => { return this.seededRandom() * 4 }

      this.tl = gsap.timeline({ scrollTrigger: {
          trigger: 'body',
          start: 'top top',
          end: scrollHeight,
          scrub: .5
        }, defaults: { ease: 'linear' } })

      this.tl
        .to(this.scene.getObjectByName('shapes'), { duration: 1, three: { y: -this.pixelsToThreeUnits(scrollHeight()) } }, 0)
        .to(this.scene.getObjectByName('shapes').children, { duration: 1, three: { rotationX: randomAngle, rotationY: randomAngle, rotationZ: randomAngle } }, 0)
    }
  },
  mounted () {
    this.container = this.$refs.threeContainer

    this.init()
  }
}
</script>

<style lang="scss">
  #foreground-animation {
    position: fixed;
    z-index: 2;
    pointer-events: none;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    margin: 0 auto;
    // max-width: var(--max-page-width);
  }
</style>
