<template>
  <div id="background-animation" ref="threeContainer">

  </div>
</template>

<script>
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DOMCamera } from "@/three/DOMCamera";

import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import gsapPlugin from './../three/gsapPlugin'
import VertexShader from 'raw-loader!@/assets/vertex-shader.txt'
import FragmentShader from 'raw-loader!@/assets/fragment-shader.txt'
import debounce from 'debounce'

gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'BackgroundAnimation',
  components: {
  },
  data () {
    return {
      seed: 4, // 5,7
      camera: null,
      horizontalFov: 100,
      scene: null,
      renderer: null,
      container: null,
      tl: null,
      unitConversionFactor: 0.001,
      material: null,
      startTime: 0
    }
  },
  methods: {
    seededRandom () {
      const x = Math.sin(this.seed++) * 10000

      return x - Math.floor(x)
    },
    pixelsToThreeUnits (val) {
      return val * this.unitConversionFactor
    },
    init () {
      let that = this
      this.camera = new DOMCamera(50, 0.001, 1000, this.unitConversionFactor, false)
      this.scene = new THREE.Scene()
      this.scene.fog = new THREE.Fog(0xffffff)
      this.scene.fog.update = function () {
        this.near = Math.abs(that.camera.position.z) + 0.2
        this.far = Math.abs(that.camera.position.z) + 0.56
      }
      this.scene.fog.update();
      this.startTime = Date.now()

      const light = new THREE.PointLight(0xffffff, 1)
      light.update = function () {
        let x = that.pixelsToThreeUnits(window.innerWidth) / 2,
            y = that.pixelsToThreeUnits(window.innerHeight) / 2

        this.position.set(x, y, -0.3)
      }
      light.name = 'light'
      light.update();
      this.scene.add(light)

      const ambientLight = new THREE.AmbientLight(0x174f62, 0.1)
      ambientLight.name = 'ambientLight'
      this.scene.add(ambientLight)

      const wireframeMaterial = new THREE.ShaderMaterial( {
        uniforms: {
          fgColor: { value: new THREE.Color(0x1d5263) },
          bgColor: { value: new THREE.Color(0xff0000) },
          color: { value: new THREE.Color(0x1d5263) },
          strength: { value: -0.2 },
          time: { // float initialized to 0
            type: "f",
            value: 0.0
          },
          fogColor: { type: "c", value: this.scene.fog.color },
          fogNear: { type: "f", value: this.scene.fog.near },
          fogFar: { type: "f", value: this.scene.fog.far }
        },
        vertexShader: VertexShader,
        fragmentShader: FragmentShader,
        //color: 0x00ff00,
        fog: true,
        wireframe: true,
        wireframeLinewidth: 2
      } );

      this.material = wireframeMaterial

      const shapes = new THREE.Group()
      shapes.name = 'shapes'
      const loader = new GLTFLoader().setPath('/')

      loader.load('shapes_v5.gltf', (gltf) => {
        //console.log(gltf)

        let bodyHeight = this.pixelsToThreeUnits(document.body.clientHeight)
        let modelHeight = 2.7
        let wireframeShapeDistribution = {
          n: Math.ceil( bodyHeight / modelHeight ) + 1,
          dy: modelHeight,
        }
        let wireframeShapeTemplates = gltf.scene.getObjectByName('shapes2').children
        //let bgutils = THREE.BufferGeometryUtils

        //console.log(bgutils)

        // distribute wireframe shapes
        for (let i = 0; i < wireframeShapeDistribution.n; i++) {
          let wireframeClone = wireframeShapeTemplates[i % wireframeShapeTemplates.length].clone();

          wireframeClone.material = wireframeMaterial //.clone()
          if ( i % 2 > 0 ) {
            wireframeClone.rotation.z = Math.PI
          }

          let scale = wireframeClone.scale.clone();
          wireframeClone.updatePosition = function () {
            this.position.y = wireframeShapeDistribution.dy * i + 1.7
            this.position.x = that.pixelsToThreeUnits(window.innerWidth / 2)
            this.scale.copy(scale.clone().multiply(new THREE.Vector3(1.5, 1.5, 1)))
          }
          wireframeClone.updatePosition()

          shapes.add(wireframeClone)
        }

        this.scene.add(shapes)

        this.render()
        this.animate()
      })

      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
      this.renderer.setPixelRatio(window.devicePixelRatio)
      this.renderer.setSize(this.container.clientWidth, this.container.clientHeight)
      this.renderer.toneMappingExposure = 1
      this.renderer.outputEncoding = THREE.sRGBEncoding

      this.container.appendChild(this.renderer.domElement)

      window.addEventListener('resize', debounce(this.onWindowResize, 200))
    },
    onWindowResize () {
      this.camera.updateAspect()
      this.camera.updateProjectionMatrix()
      this.camera.updatePosition()
      this.scene.fog.update()
      this.scene.getObjectByName('light').update()

      this.renderer.setSize(this.container.clientWidth, this.container.clientHeight)

      for (let shape of this.scene.getObjectByName('shapes').children) {
        shape.updatePosition()
      }

      this.render()
    },
    render () {
      requestAnimationFrame(this.render)
      //this.material.uniforms['time'].value = .00025 * ( Date.now() - this.startTime );
      this.renderer.render(this.scene, this.camera)
    },
    animate () {
      const scrollHeight = () => { return document.body.clientHeight - window.innerHeight }
      const randomAngle = () => { return this.seededRandom() * 2 }

      this.tl = gsap.timeline({ scrollTrigger: {
          trigger: 'body',
          start: 'top top',
          end: scrollHeight,
          scrub: .5
        }, defaults: { ease: 'linear' } })

      this.tl
        .to(this.scene.getObjectByName('shapes'), { duration: 1, three: { y: () => { return -this.pixelsToThreeUnits(scrollHeight()) } } }, 0)
        .to(this.material.uniforms['time'], { duration: 1, value: ()=>{ return scrollHeight() / 3000 } }, 0)
        //.to(this.scene.getObjectByName('shapes').children, { duration: 1, three: { rotationX: randomAngle, rotationY: randomAngle, rotationZ: randomAngle } }, 0)
    }
  },
  mounted () {
    this.container = this.$refs.threeContainer

    this.init()
  }
}
</script>

<style lang="scss">
  #background-animation {
    position: fixed;
    z-index: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    margin: 0 auto;
    // max-width: var(--max-page-width);
  }
</style>
