<template>
  <article class="scroller">
      <slot></slot>
  </article>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'Scroller',
  mounted () {
    const tl = gsap.timeline({ scrollTrigger: '.scroller', repeat: -1 })
    const slideDuration = 5
    tl
      .from('.scroller > *', { duration: 1, y: '100%', opacity: 0, stagger: slideDuration })
      .to('.scroller > *', { duration: 1, y: '-100%', opacity: 0, stagger: slideDuration }, slideDuration)
  }
}
</script>

<style lang="scss">
.scroller {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: 'content';
  line-height: 1.5;
  background-color: transparent;
  padding: 1rem;
  font-size: var(--font-size-s);

  > * {
    grid-area: content;
    display: grid;
    grid-template-columns: auto auto;

    @media all and (max-width: 599px) {
      grid-template-columns: auto;
    }

    > *{
      &:nth-child(even) {
        text-align: left;
        color: $ci-primary;
        @media all and (max-width: 599px) {
          text-align: center;
          line-height: 1;
          margin-bottom: .8em;
        }

        &:before {
          content: '-';
          display: inline-block;
          padding: 0 .5em;
          color: black;

          @media all and (max-width: 599px) {
            content: none;
            margin-bottom: .5em;
          }
        }
      }
      &:nth-child(odd) {
        text-align: right;
        @media all and (max-width: 599px) {
          text-align: center;
        }
      }
      &.col-span-2 {
        grid-column-end: span 2;
        text-align: center;
        @media all and (max-width: 599px) {
          grid-column-end: span 1;
        }
      }
    }
  }
}
</style>
