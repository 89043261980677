<template>
  <div id="app">
    <Main></Main>
  </div>
</template>

<script>
import Main from '@/components/Main'

export default {
  name: 'App',
  components: {
    Main
  }
}
</script>

<style lang="scss">
:root {
  --max-page-width-u: 80;
  --max-page-width: calc(1rem * var(--max-page-width-u));
  --font-size-s: 1rem;
  --font-size-m: 1.125rem;
  --font-size-l: 1.3125rem;
  --font-size-xl: clamp(1.5rem, 1vw * 1.8125 * 100 / var(--max-page-width-u), 1.8125rem);
  --font-size-xxl: clamp(1.7rem, 1vw * 2.125 * 100 / var(--max-page-width-u), 2.125rem);
  --font-size-xxxl: clamp(2rem, 1vw * 3.4 * 100 / var(--max-page-width-u), 3.4rem);
}

html {
  font-size: 16px !important;
}

body {
  margin: 0;
}

#app {
  font-family: 'Droid Sans', Helvetica, Arial, sans-serif;
  font-size: var(--font-size-m);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $font-color;
  margin: 0 auto;
  max-width: var(--max-page-width);
}

img {
  max-width: 100%;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.0545;
}
h1 {
  font-weight: 500;
  font-size: var(--font-size-xxxl);
}
h2 {
  font-weight: 500;
  font-size: var(--font-size-xxl);
}
h2 + h1 {
  margin-top: .3em;
}
h3 {
  font-size: var(--font-size-xl);
  color: $ci-primary-dark;
}
h4 {
  font-size: var(--font-size-l);
  color: $ci-primary;
  text-transform: uppercase;
  line-height: 1.5;
}
h5 {
  font-size: var(--font-size-m);
  font-family: 'Droid Sans', Arial, sans-serif;
  font-weight: bold;
  color: $ci-primary;
}

hgroup {
  max-width: 55rem;
  padding: 0 2rem;

  > * {
    margin: 0;
  }
}

p {
  //hyphens: auto;
  line-height: 1.5;
}

blockquote {
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: var(--font-size-xl);
  color: $ci-primary-dark;
  max-width: 40rem;
  margin: 3rem auto;

  &:last-child {
    margin-bottom: 0;
  }

  small {
    display: block;
    margin-top: .5em;
  }
}
.no-wrap {
  white-space: nowrap;
}
.color-primary {
  color: $ci-primary;
}
.color-secondary {
  color: $ci-secondary;
}
</style>
