<template>
  <div class="calendly-wrapper">
    <div v-if="inline" class="calendly-inline-widget" :data-url="dataUrl" style="min-width:320px;height:630px;"></div>
    <button v-if="!inline" ref="button" @click="clickHandler">
      <slot>zum Beratungsgespräch</slot>
    </button>
  </div>
</template>

<script>
export default {
  name: "Calendly",
  props: {
    inline: { type: Boolean, default: false },
    path: { type: String, required: true },
    backgroundColor: { type: String, default: 'ffffff' },
    color: { type: String, default: '000000' },
    primaryColor: { type: String, default: '0069FF' },
  },
  computed: {
    dataUrl: function () {
      return 'https://calendly.com/'
        + this.path + '?primary_color=' + this.primaryColor
        + '&text_color=' + this.color
        + '&background_color=' + this.backgroundColor
    }
  },
  methods: {
    clickHandler: function () {
      if (typeof Calendly !== 'undefined') {
        Calendly.initPopupWidget({ url: this.dataUrl })

        return false
      }
    }
  },
  mounted() {
    if (!document.getElementById('calendly-widget')) {
      let calendlyScript = document.createElement('script')

      calendlyScript.src = 'https://assets.calendly.com/assets/external/widget.js'
      calendlyScript.async = true
      calendlyScript.type = 'text/javascript'
      calendlyScript.id = 'calendly-widget'

      document.head.appendChild(calendlyScript)
    }
    if (!this.inline && !document.getElementById('calendly-style')) {
      let calendlyStyle = document.createElement('link')

      calendlyStyle.href = 'https://assets.calendly.com/assets/external/widget.css'
      calendlyStyle.rel = 'stylesheet'
      calendlyStyle.id = 'calendly-style'
      document.head.appendChild(calendlyStyle)
    }
  },
}
</script>

<style lang="scss">
  .calendly-inline-widget {
    border: 1px solid $ci-primary-dark;
  }
  .calendly-wrapper {
    display: contents;
  }
</style>
