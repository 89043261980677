<template>
  <main>
  <BackgroundAnimation v-if="showPageAnimation && pageAnimationMinWidth"></BackgroundAnimation>
  <header>
    <div>
      <a href="/" :class="{ hidden: showLogoAnimation }"><img src="./../assets/logo-wide.svg"></a>
      <nav>
        <ul>
          <li>
            <Calendly :path="calendlyPath" primary-color="f16800">zum Beratungsgespräch <img src="./../assets/bubbles.svg"></Calendly>
          </li>
        </ul>
      </nav>
      <ThreeD v-if="showLogoAnimation"></ThreeD>
    </div>
  </header>
  <section class="no-padding">
    <header>
      <img :src="getImgUrl(content[branch].img.header)">
      <hgroup>
        <h2>Kund:innen zuhören und verstehen.</h2>
        <h1>Mit DEEP ASSIST {{ content[branch].branchName }} zu erfolgreichem Kundenservice.</h1>
      </hgroup>
    </header>
  </section>
  <section>
    <p>
      Moderner Kundenservice muss heute zu jeder Zeit sicherstellen, dass sich Kund:innen wertgeschätzt und
      verstanden fühlen. Unabhängig davon, ob Ihr Kundenkontakt online, per Fax, telefonisch oder über
      Messenger-Dienste stattfindet. Nur wer auch in Zukunft Nachrichten schneller verstehen, zielgenau im
      Unternehmen verteilen und verarbeiten kann, wird auch beim Lösungsprozess erfolgreich sein.
    </p>
    <p>
      Noch kommt Automatisierungssoftware nur in wenigen Bereichen zum Einsatz. Eine enorme
      Chance für Unternehmen {{ content[branch].branchNameIntroduction }} verstärkt auf automatisierte Marketing-, Vertriebs- oder andere
      Prozesse zu setzen, um der Konkurrenz einen großen Schritt voraus zu sein. Aber:
      OHNE strukturierte Informationen ist keine Automatisierung erfolgreich realisierbar –
      und genau hierbei unterstützt Sie <strong class="no-wrap">DEEP ASSIST {{ content[branch].branchName }}</strong>.
    </p>
    <blockquote>
      „Wir lesen und verstehen Ihre Kundennachrichten 2.000 Mal
      schneller als einer Ihrer Mitarbeiter:innen.
      <br>Aber genauso gut.“
      <small>Roland Fleischhacker, CEO</small>
    </blockquote>
  </section>
  <section class="no-padding">
    <ul class="article-list">
      <li>
        <article class="bg-shaded">
          <hgroup>
            <h3>Guter Kundenservice „spricht“ die Sprache seiner Kund:innen. Automatisch. </h3>
            <h4>Wie DEEP ASSIST {{ content[branch].branchName }}.</h4>
          </hgroup>
          <VueSlickCarousel :arrows="true" :dots="true" :infinite="false">
            <article class="slide">
              <h5>DEEP ASSIST {{ content[branch].branchName }} versteht die „natürliche“ Sprache.</h5>
              <p>
                So wie Kund:innen schreiben, sprechen und im Alltag kommunizieren, also auch Dialekte
              oder Umgangssprache. Und: DEEP ASSIST {{ content[branch].branchName }} funktioniert auch in anderen Sprachen – das vereinfacht die Wartung um ein Vielfaches und spart auch Kosten.
              </p>
            </article>
            <article class="slide">
              <h5>DEEP ASSIST {{ content[branch].branchName }} macht aus „unstrukturierten“ Nachrichten strukturierte Informationen.</h5>
              <p>Mittels Natural Language Understanding Technology (NLU) und multiperspektivischer
              Interpretation (MPI) kann DEEP ASSIST {{ content[branch].branchName }} nicht nur unklar formulierte Texte verstehen
              sondern auch relevante Informationen herausfiltern – und somit unstrukturierte
              Nachrichten einer Prozess&shy;automatisierung zuführen.
              </p>
            </article>
            <article class="slide">
              <h5>DEEP ASSIST {{ content[branch].branchName }} ist leicht integrierbar.</h5>
              <p>Durch die einfache Integration von DEEP ASSIST {{ content[branch].branchName }} in Ihre IT-Landschaft, werden generierte,
              strukturierte Informationen wieder retour an Ihr System geschickt und können dort weiter
              verarbeitet werden. Ein Höchstmaß an Daten&shy;sicherheit und die einfache Bedienung von
                DEEP ASSIST {{ content[branch].branchName }} garantieren einen neuen Level an Kundendienst&shy;qualität.
              </p>
            </article>
          </VueSlickCarousel>
          <br>
          <picture>
            <source media="(max-width: 799px)" srcset="./../assets/info-graphic-mobile.svg">
            <source media="(min-width: 800px)" srcset="./../assets/info-graphic.svg">
            <img src="./../assets/info-graphic.svg">
          </picture>
        </article>
      </li>
      <li>
        <article>
          <hgroup>
            <h3>Guter Kundenservice ist dank
              Software&shy;unterstützung effizient.</h3>
            <h4>MIT DEEP ASSIST {{ content[branch].branchName }}.</h4>
          </hgroup>
          <VueSlickCarousel :arrows="true" :dots="true" :infinite="false">
            <article class="slide">
              <h5>DEEP ASSIST {{ content[branch].branchName }} kann von Ihren Kundenservice-Mitarbeiter:innen gewartet werden.</h5>
              <p>
              Dank Einsatz „semantischer Bausteine“ lassen sich neue Prozesse ganz einfach durch Ihr Kundenservice-Team erstellen. Durch die transparente Entscheidungsfindung des Systems lassen sich Fehlinterpretationen schnell finden und korrigieren. Und das spart Wartungsarbeiten und vor allem: Kosten.
              </p>
            </article>
            <article class="slide">
              <h5>DEEP ASSIST {{ content[branch].branchName }} reduziert CO<sub>2</sub>.</h5>
              <p>
              Große, leistungsfähige neuronale Netze benötigen immense Rechenleistung und
                produzieren so enorme Mengen an CO<sub>2</sub>. Aus diesem Grund wurde DEEP ASSIST {{ content[branch].branchName }} auf
              Basis modernster Künstlicher-Intelligenz-Technologie (KI) entwickelt: So wird durch
              den Einsatz „semantischer Bausteine“ nur ein Bruchteil an Rechenleistung
              erforderlich. Im Vergleich zu nahezu allen anderen Anbietern reduziert DEEP ASSIST {{ content[branch].branchName }}
                durch den Einsatz von „Green-KI“ den CO<sub>2</sub>-Ausstoß auf ein Minimum.
              </p>
            </article>
          </VueSlickCarousel>
          <figure>
            <img class="figure" :src="getImgUrl(content[branch].img.gartner)" alt="Hochspannungsleitung" />
            <figcaption>
              <p>
                <strong>Das bedeutendste IT-Markt&shy;forschungs&shy;unter&shy;nehmen der
                Welt hat DEEP ASSIST {{ content[branch].branchName }} unter
                die 5 weltweit besten Technologien im Bereich Conversational and Natural Language Technologies eingestuft.
                </strong>
                <br>
                (Gartner, COOL VENDOR 2021)
                <br>
                <br>
                <img src="./../assets/gartner-cool-vendor-logo.png" srcset="./../assets/gartner-cool-vendor-logo.png 1x, ./../assets/gartner-cool-vendor-logo@2x.png 2x">
              </p>
            </figcaption>
          </figure>
        </article>
      </li>
      <li>
        <article class="bg-shaded">
          <hgroup>
            <h3>Guter Kundenservice zeichnet sich durch
              schnelle Reaktionszeiten und hohe Präzision aus.</h3>
            <h4>MIT DEEP ASSIST {{ content[branch].branchName }}.</h4>
          </hgroup>
          <VueSlickCarousel :arrows="true" :dots="true" :infinite="false">
            <article class="slide">
              <h5>DEEP ASSIST {{ content[branch].branchName }} verfügt über enormes Wissen und somit auch über maximale Präzision.</h5>
              <p>
              DEEP ASSIST {{ content[branch].branchName }} arbeitet mit „Weltwissen“: eine Wissens&shy;datenbank, in der Millionen
              von Informationen so strukturiert aufgearbeitet sind, dass aus den Informationen
              Wissen entsteht. Das auf diese Weise gespeicherte, leicht abrufbare Wissen kann
              von DEEP ASSIST {{ content[branch].branchName }} in Millisekunden abgerufen werden. So werden Inhalte jeglichen
              Formats verstanden und jeder Suchanfrage kann eine passende und qualitativ
              hochwertige Antwort gegeben werden.
              </p>
            </article>
            <article class="slide">
              <h5>DEEP ASSIST {{ content[branch].branchName }} verkürzt die Trainingszeiten neuer Kundenservice-Mitarbeiter:innen.</h5>
              <p>
              Da DEEP ASSIST {{ content[branch].branchName }} auf eine eigene „Weltwissen“-Datenbank zugreift, kann die
              Einschulungsdauer neuer Mitarbeiter:innen von Monaten auf Tage reduziert werden,
              da das eigentliche Fachwissen nicht explizit trainiert werden muss.
              </p>
            </article>
            <article class="slide">
              <h5>DEEP ASSIST {{ content[branch].branchName }} findet unerwartete Ergebnisse im Handumdrehen.</h5>
              <p>
              Automatisierte Prozesse müssen nachvollziehbar sein – doch die Suche nach
              „Fehlern“ ist bei fast allen Anbietern ein zumeist sehr zeitaufwendiger Prozess.
              DEEP ASSIST {{ content[branch].branchName }} verfügt daher über eine „transparente KI“, die es ermöglicht,
              ungeplante Resultate mit nur wenigen Mausklicks zu entdecken und sofort
              neu anzupassen.
              </p>
            </article>
          </VueSlickCarousel>
        </article>
      </li>
      <li>
        <article>
          <hgroup>
            <h3>Guter Kundenservice kennt die Anliegen
              Ihrer Kund:innen. Und Ihres Teams.</h3>
            <h4>WIE DEEP ASSIST {{ content[branch].branchName }}.</h4>
          </hgroup>
          <VueSlickCarousel :arrows="true" :dots="true" :infinite="false">
            <article class="slide">
              <h5>DEEP ASSIST {{ content[branch].branchName }} versteht die Sorgen des Kundendienstes.</h5>
              <p>
              10 Jahre „Leading Edge-Technology“ ist nur eine Seite der Medaille: DEEP ASSIST {{ content[branch].branchName }}
                wurde von erfahrenen IT-, Projektmanagement- und Linguistik-Expert:innen entwickelt und genau auf die
                Anforderungen und den täglichen Einsatz von Kundenservice-Mitarbeiter:innen spezialisiert.
              </p>
            </article>
            <article class="slide">
              <h5>DEEP ASSIST {{ content[branch].branchName }} ist einfach und transparent zu kalkulieren.</h5>
              <p>
              Keine bösen Überraschungen: DEEP ASSIST {{ content[branch].branchName }} arbeitet mit einem fairen
              „Pay per Use“-Modell. Das erleichtert Ihrem Unternehmen das Kalkulieren der Kosten und garantiert Ihnen in jeder Situation absolute Transparenz.
              </p>
            </article>
          </VueSlickCarousel>
        </article>
      </li>
    </ul>
  </section>
  <section class="bg-shaded">
    <h3>Profitieren Sie von vorkonfigurierten {{ content[branch].branchNameProfit }}-Prozessen</h3>
    <p><strong class="color-primary">DEEP ASSIST {{ content[branch].branchName }}</strong> bietet Ihnen über {{ content[branch].processCount2 }} branchenspezifische Inhalte und Wordings
      sowie über {{ content[branch].processCount }} typische Kundenanliegen aus {{ content[branch].branchNameProfit2 }}.</p>
    <Scroller>
      <div v-for="(processGroup, indexProcessGroup) in content[branch].processes">
        <template v-for="(process, indexProcess) in processGroup">
          <template v-for="(processPart, indexProcessPart) in process">
            <div v-if="indexProcessGroup == content[branch].processes.length - 1 && indexProcess == processGroup.length - 1" class="col-span-2">
              <strong class="color-secondary">
                {{ processPart }}
              </strong>
            </div>
            <div v-else>
              {{ processPart }}
            </div>
          </template>
        </template>
      </div>
    </Scroller>
  </section>
  <section>
    <hgroup>
      <h3>Ihre Vorteile auf einen Blick: </h3>
      <h4>MIT DEEP ASSIST {{ content[branch].branchName }}.</h4>
    </hgroup>
    <br>
    <ul class="two-column-list">
      <li :class="branch">
        <p>
          <strong>Vor&shy;konfigurierte Branchen&shy;lösung
            <span v-html="content[branch].solutionFor"></span>:</strong> Nutzen Sie das
          aus einer Vielzahl von erfolgreichen
          Projekten gewonnene Wissen in Form
          unseres spezifischen Wissens&shy;graphen,
          semantischer Bausteine und vieler
          vor&shy;konfigurierter Prozesse.
        </p>
      </li>
      <li>
        <p>
          <strong>Multi&shy;perspektivische
            Interpretation:</strong> Analysieren Sie
          eingehende Nachrichten aus
          verschiedenen Blickwinkeln, wie
          Aufgaben&shy;stellung, Dringlichkeit,
          Stimmung und optimieren Sie so
          die Weiter&shy;verarbeitung.
        </p>
      </li>
      <li>
        <p>
          <strong>Multi-Intent-Erkennung:</strong>
          Identifizieren Sie mehrere Anliegen
          in der Kunden&shy;nachricht und führen
          Sie jedes einzelne Anliegen
          automatisch einer Lösung zu.
        </p>
      </li>
      <li>
        <p>
          <strong>Ein Sprachmodell für alle
            Kommunikations&shy;kanäle:</strong>
          Harmonisieren Sie Ihre Kundenservice-
          Prozesse über alle Kanäle bei
          minimalem Wartungs&shy;aufwand.
        </p>
      </li>
      <li>
        <p>
          <strong>Prozess&shy;automatisierung:</strong>
          Automatisieren Sie mit dem
          Zusammenspiel von
          Anliegens&shy;erkennung und
          Informations-Extraktion Ihre
          Kunden&shy;service-Prozesse bis zu 100 %.
        </p>
      </li>
      <li>
        <p>
          <strong>Nach&shy;voll&shy;zieh&shy;bar&shy;keit:</strong> Behalten
          Sie volle Kontrolle über die
          Entscheidungs&shy;prozesse der KI.
        </p>
      </li>
    </ul>
  </section>
  <section class="bg-shaded">
    <p v-if="!staging">
      Kontaktieren Sie uns und erfahren Sie mehr über die Vorteile
      und das enorme Einsparungs&shy;potenzial.<br>Wir freuen uns auf Sie!
    </p>
    <p v-if="staging">
      Vereinbaren Sie gleich einen Termin für ein persönliches Beratungsgespräch und erfahren Sie mehr über die Vorteile und das enorme Einsparungs­potenzial.
      <br>
      Wir freuen uns auf Sie!
    </p>
  </section>
  <section class="sticky-bottom bg-shaded">
    <Calendly :path="calendlyPath" primary-color="f16800">zum Beratungsgespräch <img class="icon" src="./../assets/bubbles-white.svg"></Calendly>
  </section>
  <footer>
    <p>
      DEEP ASSIST {{ content[branch].branchName }} ist ein Produkt der <strong class="no-wrap">© 2022 DEEPSEARCH GmbH</strong>
      <br>Mehr Informationen finden Sie unter <a href="https://www.deepsearch.at">www.deepsearch.at</a>
    </p>
    <nav>
      <ul>
        <li><a href="#contact" v-on:click.stop.prevent="showContact = !showContact">KONTAKT</a></li>
        <li class="full-width" id="contact" v-if="showContact">
          <div class="grid-2-col">
            <div>Adresse:</div><div>Opernring 1/E/639, 1010 Vienna, Austria</div>
            <div>Telefon:</div><div><a href="tel:+43-1-352-35-35">+43 1 352 35 35</a></div>
            <div>E-Mail:</div><div><a href="mailto:info@deepsearch.at">info@deepsearch.at</a></div>
          </div>
        </li>
        <li><a href="#imprint" v-on:click.stop.prevent="showImprint = !showImprint">IMPRESSUM</a></li>
        <li class="full-width" id="imprint" v-if="showImprint">
          <div class="grid-2-col">
            <div>Firmenname:</div><div>DEEPSEARCH GmbH</div>
            <div>Handelsregister:</div><div>344167d, Handelsgericht Wien</div>
            <div>UID Nummer:</div><div>ATU65698444</div>
            <div>ECG:</div><div>District Municipal Office for District I</div>
          </div>
        </li>
        <li><a href="https://www.deepsearch.at/datenschutzerklaerung/" target="_blank">DATENSCHUTZ</a></li>
      </ul>
    </nav>
  </footer>
  <ForegroundAnimation v-if="showPageAnimation && pageAnimationMinWidth"></ForegroundAnimation>
  <div v-if="showLogoAnimation" id="curtain"></div>
  </main>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import Scroller from '@/components/Scroller'
import { balanceText } from 'balanced-text'
import ThreeD from '@/components/ThreeD'
import BackgroundAnimation from "@/components/BackgroundAnimation";
import ForegroundAnimation from "@/components/ForegroundAnimation";
import Calendly from "@/components/Calendly";

gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'Main',
  components: {
    Calendly,
    BackgroundAnimation,
    ForegroundAnimation,
    Scroller,
    VueSlickCarousel,
    ThreeD
  },
  data () {
    return {
      content: {
        facility: {
          branchName: 'Facility Services',
          branchNameIntroduction: 'im Facility Service',
          branchNameProfit: 'Facility Services',
          branchNameProfit2: 'dem Beschwerde-, Objekt- oder Schadensmanagement',
          processCount: '1.000',
          processCount2: '170.000',
          solutionFor: 'für das Facility Management',
          img: {
            header: 'header-facility.jpg',
            gartner: 'facility.jpg'
          },
          processes: [
            [
              ['Immobiliengesuch','Voraussetzungen - Auskunft'],
              ['Immobiliengesuch','Antrag'],
              ['Immobiliengesuch','Änderung - Wohnungsgröße/Miethöhe …'],
              ['Immobiliengesuch','Übergabe - Terminvereinbarung'],
              ['Immobiliengesuch','Förderungsanspruch - Auskunft'],
            ],
            [
              ['Immobilienrückgabe','Ablauf - Reklamation'],
              ['Immobilienrückgabe','Schadensbehebung - Antrag'],
              ['Immobilienrückgabe','Zwangsräumung - Frist'],
              ['Immobilienweitergabe','Antrag'],
              ['Wohnungsbegehung','Zustandserhebung'],
            ],
            [
              ['Mietverhältnis','Hauptmieterbestätigung'],
              ['Wohnkosten','Betriebskostenaufstellung'],
              ['Wohnkosten','Mietzinshöhe - Reklamation'],
              ['Liegenschaft','Kameraüberwachung - Auskunft'],
              ['Liegenschaft','Fahrradabstellplatz - Antrag'],
            ],
            [
              ['Liegenschaft','Schlüsselkarte - Verlust'],
              ['Liegenschaft','Parkplatz - Falschparker'],
              ['Liegenschaftsumbau','Wohnungszusammenlegung - Auskunft'],
              ['Montageansuchen','SAT-Antenne'],
              ['Gerätewartung', 'Spielplatzgeräte - Reklamation'],
            ],
            [
              ['Verunreinigungsbeschwerde','Sperrmüll/Graffiti/…'],
              ['Lärmbelästigung','Geschrei'],
              ['Geruchsbelästigung','Hausmüll'],
              ['Schadensmeldung','Wasserleitung/Fenster/Aufzug …'],
              ['… und über 900 weitere Prozesse']
            ]
          ]
        },
        utility: {
          branchName: 'Utilities',
          branchNameIntroduction: '',
          branchNameProfit: 'Utility',
          branchNameProfit2: 'dem Bereich Energieversorgung und Netzbetrieb',
          processCount: '80',
          processCount2: '160.000',
          solutionFor: 'für Energie&shy;versorger',
          img: {
            header: 'header.jpg',
            gartner: 'power-lines.jpg'
          },
          processes: [
            [
              ['An-/Ab-/Ummeldung','Gas'],
              ['An-/Ab-/Ummeldung','Strom'],
              ['An-/Ab-/Ummeldung','Wasser'],
              ['Gasliefervertrag','Unterzeichnet - Übermittelung'],
              ['Stromliefervertrag','Unterzeichnet - Übermittelung'],
            ],
            [
              ['Abschlagsplan','Änderung'],
              ['Zählerstand','Meldung - Gas/Strom/Wasser'],
              ['Kündigung','Gas/Strom/Wasser'],
              ['Kündigung','Gas/Strom - Wohnsitzänderung'],
              ['Kündigung','Gas/Strom - Lieferantenwechsel'],
            ],
            [
              ['Einzugsermächtigung','Entzug/Erteilung'],
              ['Bankdatenänderung','Bankverbindung löschen'],
              ['Bankdatenänderung','neue Bankverbindung'],
              ['Guthaben','Auszahlung'],
              ['Datenschutz','Datenverarbeitung - Anliegen (DSGVO)'],
            ],
            [
              ['Rückruf','Anfrage'],
              ['Rücksprache/Rücksprache','Bedarfsfall'],
              ['Rechnung','Rechnungskopie - Antrag'],
              ['Zahlungshinweis','Mitteilung'],
              ['Widerruf'],
            ],
            [
              ['Stammdatenänderung','Namensänderung'],
              ['Stammdatenänderung','E-Mail-Adresse'],
              ['Stammdatenänderung','Rechnungsadresse - Änderung'],
              ['Stammdatenänderung','Telefonnummer - Änderung'],
              ['… und über 50 weitere Prozesse']
            ]
          ]
        },
        transport: {
          branchName: 'Public Transport',
          branchNameIntroduction: 'im öffentlichen Bereich',
          branchNameProfit: 'Public Transport',
          branchNameProfit2: 'dem Bereich des öffentlichen Verkehrs',
          processCount: '1.000',
          processCount2: '160.000',
          solutionFor: 'für den öffentlichen Verkehr',
          img: {
            header: 'header-transport.jpg',
            gartner: 'transport.jpg'
          },
          processes: [
            [
              ['Fahrkarte','Preis - Auskunft'],
              ['Fahrkarte','Kauf'],
              ['Fahrkarte','Personenmitnahme - Auskunft'],
              ['Fahrkarte','Geltungsbereich - Änderung'],
              ['Fahrkarte','Online - Ticket - Auskunft']
            ],
            [
              ['Sitzplatzreservierung','Preisauskunft'],
              ['Sitzplatzreservierung','Reisegruppe - Auskunft'],
              ['Sitzplatzreservierung','Reservierungsanzeige - Reklamation'],
              ['Zugreise','Mitnahme - Begleithund - Auskunft'],
              ['Zugreise','Mitnahme - Großgepäck']
            ],
            [
              ['Zugreise','Zugverspätung - Meldung'],
              ['Bus','Ausstattung - Auskunft'],
              ['Zugreise','Umleitung - Auskunft'],
              ['Schiff','Fahrkarte - Ermäßigung - Auskunft'],
              ['Zugreise','Mobilfunkempfang - Reklamation']
            ],
            [
              ['Bahnhof','Bauarbeiten - Auskunft'],
              ['Bahnhof','Bankomat - Auskunft'],
              ['Bahnhof','Fahrrad - Stellplatz - Auskunft'],
              ['Fahrkartenautomat','Zahlungsmittel - Auskunft'],
              ['Fahrkartenautomat','Störung - Meldung']
            ],
            [
              ['Bahnkundenkarte','Bestellung - Antrag'],
              ['Kundenkarte','Verlust - Meldung/Kündigung/…'],
              ['Fahrplan','Abfahrtszeit - Auskunft'],
              ['Fahrplan','Umsteigeverbindung - Auskunft'],
              ['… und über 900 weitere Prozesse']
            ]
          ]
        }
      },
      calendlyPath: 'joseph-hotter/ki-in-der-automatisierung-von-kommunikation-tbd',
      showImprint: false,
      showContact: false,
      showLogoAnimation: false,
      showPageAnimation: false,
      pageAnimationMinWidth: false,
      branch: 'utility',
      staging: false
    }
  },
  methods: {
    resizeHandler() {
      this.pageAnimationMinWidth = window.matchMedia('(min-width: 769px)').matches;
    },
    getImgUrl(url) {
      return require('@/assets/' + url)
    }
  },
  created() {
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);

    switch (params.get('animations')) {
      case 'page':
        this.showLogoAnimation = false
        this.showPageAnimation = true
        break
      case 'none':
        this.showLogoAnimation = false
        this.showPageAnimation = false
        break
      case 'all':
      case 'true':
      default:
        this.showLogoAnimation = true
        this.showPageAnimation = true
    }

    let branch = params.get('branch')
    let hostname = window.location.hostname

    branch = branch ? branch : hostname

    switch (branch) {
      case 'facilityservices.deepassist.com':
      case 'facility':
        this.branch = 'facility'
        break
      case 'publictransport.deepassist.com':
      case 'transport':
        this.branch = 'transport'
        break
      default:
        this.branch = 'utility'
        break
    }

    if (params.get('staging') === 'true')
      this.staging = true

    this.resizeHandler()

    window.addEventListener('resize', () => {
      this.resizeHandler();
    })
  },
  mounted () {
    document.querySelectorAll('figure').forEach((item) => {
      const tl = gsap.timeline({ scrollTrigger: { trigger: item, start: 'center bottom' } })

      tl
        .from(item, { duration: 1, opacity: 0, x: 100 })
        .from(item.querySelector('img.figure'), { duration: 1, opacity: 0, x: -200 }, 0)
    })

    balanceText({
      elements: 'h3, h4, .balance-text',
      watch: true,
      lazyBalance: false,
      disableWait: false
    })
  }
}
</script>

<style lang="scss">
  main {
    --section-padding: 3rem 15%;
    padding-top: 6.25rem;

    @media all and (max-width: 599px) {
      --section-padding: 2rem;
      padding-top: 0;
    }

    #curtain {
      position: fixed;
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 0;
      background-color: white;
      z-index: 2000;
    }

    > * {
      position: relative;
      z-index: 1;
    }

    > header {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background-color: white;
      box-shadow: 0 .2rem 0 transparentize($ci-primary-dark, .8);
      z-index: 1000;

      > div {
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        overflow: hidden;
        max-width: var(--max-page-width);
        margin: 0 auto;
        box-sizing: border-box;
        position: relative;
      }

      @media all and (max-width: 599px) {
        flex-direction: column;
        align-items: center;
        position: relative;

        nav {
          display: none !important;
        }
      }

      a.hidden {
        opacity: 0;
      }

      nav {
        display: flex;
        align-items: center;

        ul {
          margin: 0;

          li {
            a, button {
              color: $ci-primary-dark;
              border: none;
              background-color: transparent;
              font-size: var(--font-size-s);
              text-transform: uppercase;
              text-decoration: none;
              transition: color .5s;
              font-family: 'Montserrat', Helvetica, Arial, sans-serif;
              cursor: pointer;

              &:hover {
                color: $ci-primary;
              }
            }
          }
        }
      }
    }
    > section {
      padding: var(--section-padding);

      &.no-padding {
        padding: 0;
      }

      > header {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas: "header";

        img, hgroup {
          grid-area: header;
        }

        img {
          min-height: 20rem;
          object-fit: cover;
        }

        hgroup {
          justify-self: center;
          align-self: center;
          color: white;
          text-shadow: 2px 2px 5px rgba(25, 88, 112, 0.22);
        }
      }
    }
  }

  ul.article-list {
    counter-reset: article;
    list-style-type: none;
    padding: 0;

    > li {
      > article {
        padding: var(--section-padding);
        overflow: hidden;

        hgroup {
          text-align: left;
          padding-left: 4.44rem;
          position: relative;

          &:before {
            position: absolute;
            left: 0;
            top: 0.4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 3.4375rem;
            height: 3.4375rem;
            border: 2px solid $ci-primary-dark;
            color: $ci-secondary;
            font-weight: bold;
            font-size: 1.9375rem;
            counter-increment: article;
            content: counter(article, decimal-leading-zero);
          }
        }
      }
    }
  }

  ul.two-column-list {
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem 2rem;

    @media all and (max-width: 699px) {
      grid-template-columns: 1fr;
    }

    > li {
      display: block;
      text-align: left;
      position: relative;
      padding-left: 4.4rem;

      &:before {
        content: url("./../assets/list-icon-01.svg");
        position: absolute;
        left: 0;
        top: 0;
      }
      &.facility:before { content: url("./../assets/icon-facility.svg"); }
      &.transport:before { content: url("./../assets/icon-transport.svg"); }
      &:nth-child(2):before { content: url("./../assets/icon-multiperspektivische-interpretation.svg"); }
      &:nth-child(3):before { content: url("./../assets/icon-multi-intent-erkennung.svg"); }
      &:nth-child(4):before { content: url("./../assets/icon-sprachmodell.svg"); }
      &:nth-child(5):before { content: url("./../assets/icon-prozessautomatisierung.svg"); }
      &:nth-child(6):before { content: url("./../assets/icon-nachvollziehbarkeit.svg"); }

      p {
        margin-top: 0;
      }
    }
  }

  nav {
    ul {
      list-style-type: none;
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      li {
        padding: .5em 1em;
      }
    }
  }

  figure {
    position: relative;
    background-image: linear-gradient(to bottom, #2d90ae, #2e93ae 10%, #2e97ae 20%, #2f9cad 30%, #30a0ac 40%, #31a5ab 50%, #32aaaa 60%, #33b0a9 70%, #35b5a8 80%, #36bba6 90%, #37c0a4);
    display: flex;
    margin: 4rem 0 4rem 5%;

    > img {
      position: relative;
      left: -5%;
      top: -2rem;
      width: 50%;
      align-self: start;
    }

    figcaption {
      position: relative;
      font-size: var(--font-size-s);
      color: white;
      text-align: left;

      p {
        padding: 2rem 2rem 2rem 0;
      }
    }

    @media all and (max-width: 679px) {
      flex-direction: column;

      > img {
        width: auto;
      }

      figcaption {
        p {
          padding: 0 2rem 2rem;
          margin: 0;
        }
      }
    }
  }

  .bg-shaded {
    background-color: $ci-primary-light;
  }

  a img.icon, button img.icon {
    width: 1.3em;
    height: 1.3em;
    object-fit: contain;
    display: inline;
    vertical-align: sub;
  }

  a.button, .sticky-bottom button {
    display: inline-block;
    background-color: $ci-secondary;
    color: white;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    padding: 1em 1.5em;
    text-decoration: none;
    text-transform: uppercase;
    font-size: var(--font-size-s);
    transition: background-color .5s;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: #ff9a00;
    }

    img.icon {
      margin-left: .2em;
    }
  }

  footer {
    color: white;
    background-color: $ci-primary;
    padding: 1.5rem 10%;

    a {
      color: white;
      text-decoration: none;
      font-weight: bold;
    }

    p {
      margin: 1em auto;
      max-width: 35rem;
    }

    nav > ul {
      justify-content: center;
    }
  }

  .sticky-bottom {
    padding-top: 0;

    @media all and (max-width: 599px) {
      position: sticky;
      bottom: 0;
      padding: 1rem;
    }
  }

  .full-width {
    width: 100%;
  }

  article.slide {
    padding: .5em 1em;
    box-sizing: border-box;

    > * {
      max-width: 55rem;
      margin-left: auto;
      margin-right: auto;
    }

    h5 {
      margin-bottom: 0.2em;
      margin-top: 0;
    }
    h5 + p {
      margin-top: 0;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }

  .slick-slider {
    margin: 2rem 0;

    .slick-arrow {
      width: 1.6rem;
      height: 1.6rem;
      background-image: url("./../assets/slider-next.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      transition: opacity .5s;

      &:before {
        content: none !important;
      }

      &.slick-prev {
        background-image: url("./../assets/slider-prev.svg");
      }

      &.slick-disabled {
        opacity: 0;
      }
    }
    .slick-dots li {
      margin: 0;

      button:before {
        color: $ci-secondary;
        font-size: .7rem;
      }
    }
  }
  .grid-2-col {
    display: inline-grid;
    grid-template-columns: auto auto;
    grid-gap: .2em 1em;
    text-align: left;
  }
</style>
